.loadSheet {
  dt {
    float: left;
    clear: left;
    width: 160px;
  }

  dd {
    margin: 0 0 0 80px;
    padding: 0 0 0.5em 0;
  }
}

.route {
  margin-bottom: 25px;
  font-family: 'Courier New', Courier, monospace;
  background-color: #f0f0f0;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  padding: 5px 10px;
  color: red;
}

.text-right {
  text-align: right;
}
